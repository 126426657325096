import { DataTable } from '@/components/dataTable/DataTable';
import { feedbackRoute } from '@/routes/feedbackRoute';
import { trpc } from '@/services/trpc';
import type { PaginationParams } from '@/types';

import { useFeedbacksCol } from '../hooks/useFeedbacksCol';

export function UserFeedBacksTable() {
  const columns = useFeedbacksCol();
  const { userId } = feedbackRoute.useParams();
  const useQuery = ({ pageSize, pageIndex }: PaginationParams) =>
    trpc.aiCoreRouter.getUserFeedbacksPaginated.useQuery({
      userId: userId,
      pageSize: pageSize,
      pageIndex: pageIndex,
    });
  return <DataTable columns={columns} useQuery={useQuery} />;
}
