import {
  createFaqSchema,
  updateFaqSchema,
  type Faq,
  type FaqCreateDTO,
} from '@form-factory-ai/admin-api/src/schema/schema';
import { Button, toast } from '@utima/ui';
import * as Form from '@utima/ui-informed';
import type { FormApi } from 'informed';
import { Info } from 'lucide-react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormActions } from '@/components/formActions/FormActions';
import { trpc } from '@/services/trpc';

type FaqFormProps = {
  data?: Faq;
  onCancel?: () => void;
  onSubmit?: () => void;
};

interface ResultData {
  questionCZ: string;
  questionEN: string;
  answerEN: string;
  answerCZ: string;
}

export function FaqForm({ data, onCancel, onSubmit }: FaqFormProps) {
  const { t } = useTranslation(['form', 'faqs']);
  const formApiRef = useRef<FormApi>();
  const utils = trpc.useUtils();
  const [loading, setLoading] = useState(false);
  const createFaqMutation = trpc.faq.createFaq.useMutation();
  const updateFaqMutation = trpc.faq.updateFaq.useMutation();
  const generateFaqMutation = trpc.faq.generateFAQ.useMutation();

  const handleSubmit = async (
    formState: Form.TypedFormState<FaqCreateDTO | undefined>,
  ) => {
    const { values } = formState;

    if (!values) {
      return;
    }

    try {
      await (data
        ? updateFaqMutation.mutateAsync({ id: data.id, ...values })
        : createFaqMutation.mutateAsync(values));

      toast.success(t('form:actions.success.title'), {
        description: t('form:actions.success.message'),
      });

      utils.faq.getFaqs.invalidate();

      onSubmit?.();
    } catch {
      toast.error(t('form:actions.fail.title'), {
        description: t('form:actions.fail.message'),
      });
    }
  };

  const random = (formApi: FormApi, result: ResultData) => {
    formApi.setValue('question_en', result.questionEN);
    formApi.setValue('answer', result.answerCZ);
    formApi.setValue('answer_en', result.answerEN);
  };

  const handleGenerateQuestion = async () => {
    try {
      setLoading(true);
      const userQuestion = formApiRef.current?.getValue('question') as string;
      if (!formApiRef.current || !userQuestion) {
        setLoading(false);
        return;
      }
      const result: ResultData = await generateFaqMutation.mutateAsync({
        question: userQuestion,
        userId: 'b19f8c8d-3f49-4d2a-957a-8cb6b9c8fa6b',
      });

      formApiRef.current?.getValue('question');

      random(formApiRef.current, result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error generating question:', error);
    }
  };

  return (
    <Form.Form
      formApiRef={formApiRef}
      initialValues={data}
      onSubmit={handleSubmit}
      zodSchema={data ? updateFaqSchema : createFaqSchema}
      className='flex w-full flex-col gap-2.5 p-3'
    >
      <Form.Input
        name='title'
        label={t('faqs:form.title')}
        placeholder={t('faqs:form.title')}
        required
      />
      <Form.Input
        name='title_en'
        label={t('faqs:form.titleEn')}
        placeholder={t('faqs:form.titleEn')}
        required
      />
      <Form.Input
        name='question'
        label={t('faqs:form.question')}
        placeholder={t('faqs:form.question')}
        validateOnMount
        showErrorIfError
        required
      />

      <Button onClick={handleGenerateQuestion} disabled={loading}>
        {t('faqs:form.generate.button')}
      </Button>
      <div className='text-sm'>
        <Info className='mr-2.5 inline size-5' />
        {t('faqs:form.generate.info')}
      </div>

      <Form.Input
        name='question_en'
        label={t('faqs:form.questionEn')}
        placeholder={t('faqs:form.questionEn')}
        required
      />
      <Form.TextArea
        name='answer'
        label={t('faqs:form.answer')}
        placeholder={t('faqs:form.answer')}
        required
      />
      <Form.TextArea
        name='answer_en'
        label={t('faqs:form.answerEn')}
        placeholder={t('faqs:form.answerEn')}
        required
      />

      <FormActions onCancel={onCancel} />
    </Form.Form>
  );
}
