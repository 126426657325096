import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionCellMessages } from '../messageManagementTable/ActionCell';

export interface MessagesType {
  userId: string;
  threadsCount: number;
  feedbacksCount: number;
}

const columnHelper = createColumnHelper<MessagesType>();

export function useMessagesCol() {
  const { t } = useTranslation('messageManagement');

  return useMemo(
    () => [
      columnHelper.accessor('threadsCount', {
        header: t('tableHead.threadsCount'),
      }),
      columnHelper.accessor('feedbacksCount', {
        header: t('tableHead.feedbackCount'),
      }),
      columnHelper.accessor('userId', {
        header: t('tableHead.userId'),
      }),
      columnHelper.display({
        id: 'actions',
        header: t('tableHead.actions'),
        cell: ({ row }) => <ActionCellMessages userObject={row.original} />,
        meta: {
          className: 'w-52 text-center',
        },
      }),
    ],
    [t],
  );
}
