import './services/i18n';
import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { FormTranslationsContext } from '@utima/ui-informed';
import { StrictMode, Suspense } from 'react';

import './app.css';

import { LeftSidebarHistory } from './components/leftSideBarHistory/LeftSideBarHistory';
import { Loader } from './components/loader/Loader';
import { useFormTranslations } from './hooks/useFormTranslations';
import { router } from './routes/router';
import { queryClient } from './services/reactQuery';
import { trpc, trpcClient } from './services/trpc';

export function App() {
  const translations = useFormTranslations();
  return (
    <StrictMode>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <FormTranslationsContext.Provider value={translations}>
                <LeftSidebarHistory />

                <RouterProvider router={router} />
              </FormTranslationsContext.Provider>
            </QueryClientProvider>
          </trpc.Provider>
        </Suspense>
      </ErrorBoundary>
    </StrictMode>
  );
}
