import { DataTable } from '@/components/dataTable/DataTable';
import { trpc } from '@/services/trpc';
import type { PaginationParams } from '@/types';

import { useMessagesCol } from '../hooks/useMessagesCol';

export function MesssagesManagementTable() {
  const columns = useMessagesCol();

  const useQuery = ({ pageSize, pageIndex }: PaginationParams) =>
    trpc.aiCoreRouter.getInfoMessagesPaginated.useQuery({
      pageSize: pageSize,
      pageIndex: pageIndex,
    });

  return <DataTable columns={columns} useQuery={useQuery} />;
}
