import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import type z from 'zod';

import { refreshTokenTable } from '../schema';

const selectRefreshTokenSchema = createSelectSchema(refreshTokenTable, {});

const insertRefreshTokenSchema = createInsertSchema(refreshTokenTable, {}).omit(
  {
    created_at: true,
  },
);

const createRefreshTokenSchema = insertRefreshTokenSchema;

const findRefreshTokenByUserIdSchema = selectRefreshTokenSchema.pick({
  user_id: true,
});

const findRefreshTokenByTokenSchema = selectRefreshTokenSchema.pick({
  token: true,
});

export type RefreshTokenCreateDTO = z.infer<typeof createRefreshTokenSchema>;
export type RefreshTokenFindByUserIdDTO = z.infer<
  typeof findRefreshTokenByUserIdSchema
>;
export type RefreshTokenFindByTokenDTO = z.infer<
  typeof findRefreshTokenByTokenSchema
>;
